<template>
  <transition name="fade" mode="out-in">
    <div
      v-if="!pageLoadReady"
      key="loading"
      class="has-padding has-text-centered"
    >
      <ui-loader />
    </div>

    <layout-page-menu
      v-else
      :heroTitle="pageTitle"
      :heroSubTitle="pageSubTitle"
      heroAdditionalSubTitleClass="no-margin-bottom"
    >
      <template v-if="reservation" slot="pageHeroSubSection">
        <span class="is-size-7 has-text-weight-light is-italic has-text-grey"
          >{{ $t('General.Created-on') }}
          {{
            reservation.CreatedOn
              | convertTimeStampToIsoDateTimeString
              | dateFormat($i18n.locale, 'longDateFormat')
          }}
          {{ ($t('General.On') + ' ' + $t('General.Channel')).toLowerCase() }}
          {{ reservation.ChannelName }}</span
        >

        <span
          v-if="reservation.ReservationTerms"
          class="is-size-7 has-text-weight-light is-italic has-text-grey"
          >, Terms: {{ reservation.ReservationTerms.BookingTerm.Version }}</span
        >
        <div>
          <div class="level">
            <div class="level-left">
              <nav class="navbar">
                <a @click="$router.go(-1)" class="navbar-item">
                  <span class="icon is-small">
                    <font-awesome-icon :icon="['fas', 'chevron-left']" />
                  </span>
                  <span>{{
                    $t('Components.Reservation.ReservationMailLog.Icon_Back')
                  }}</span>
                </a>
              </nav>
            </div>
            <div class="level-right">
              <span
                v-for="(reservationProfile, index) in reservationProfiles"
                :key="index"
                class="image is-32x32"
              >
                <img
                  src="@/assets/images/spacer1_1.gif"
                  class="is-rounded has-shadow has-background-grey-light"
                  :style="{
                    backgroundImage: `url(${$options.filters.getProfileImage(
                      reservationProfile.ProfileImage,
                      reservationProfile.ProfileId,
                      38
                    )})`,
                  }"
                />
              </span>
            </div>
          </div>
        </div>
      </template>
      <template slot="pageMenu">
        <nav class="navbar is-hidden-print">
          <a @click="$router.go(-1)" class="navbar-item">
            <span class="icon is-small">
              <font-awesome-icon :icon="['fas', 'chevron-left']" />
            </span>
            <span>{{
              $t('Components.Reservation.ReservationMailLog.Icon_Back')
            }}</span>
          </a>
        </nav>
        <ui-page-menu :menuItems="pageMenu" />
      </template>
      <template slot="pageContent">
        <div class="has-margin-top">
          <div class="is-hidden-print" v-if="reservation.SetId > 0">
            <span>
              <button
                class="button"
                @click="goToPreviousReservationInSet"
                :disabled="previousInSet === -1"
              >
                <span class="icon">
                  <font-awesome-icon :icon="['fas', 'chevron-left']" />
                </span>
              </button>
            </span>
            <span>
              <button
                class="button"
                @click="goToNextReservationInSet"
                :disabled="nextInSet === -1"
              >
                <span class="icon">
                  <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </span>
              </button>
            </span>
          </div>

          <!-- <transition name="slide-fade" mode="out-in"> -->
          <ReservationDetail
            v-if="visiblePageComponent === viewPageComponents.detail"
            :key="viewPageComponents.detail"
          />

          <reservation-checkins
            v-if="visiblePageComponent === viewPageComponents.checkins"
            :key="viewPageComponents.checkins"
          />
          <reservation-comments
            v-if="visiblePageComponent === viewPageComponents.comments"
            :key="viewPageComponents.comments"
          />
          <reservation-contacts
            v-if="visiblePageComponent === viewPageComponents.contacts"
            :key="viewPageComponents.contacts"
          />
          <reservation-notes
            v-if="visiblePageComponent === viewPageComponents.notes"
            :key="viewPageComponents.notes"
          />
          <reservation-todos
            v-if="visiblePageComponent === viewPageComponents.todos"
            :key="viewPageComponents.todos"
          />
          <reservation-program
            v-if="visiblePageComponent === viewPageComponents.program"
            :key="viewPageComponents.program"
            :reservationId="reservation.Id"
            :endMinutes="reservation.EndMinutes"
            :startMinutes="reservation.StartMinutes"
          />
          <reservation-mail
            v-if="visiblePageComponent === viewPageComponents.maillog"
            :key="viewPageComponents.maillog"
          />
          <reservation-event
            v-if="visiblePageComponent === viewPageComponents.eventCalendar"
            :key="viewPageComponents.eventCalendar"
          />
          <reservation-revisions
            v-if="visiblePageComponent === viewPageComponents.revisions"
            :reservationId="reservation.Id"
            :currentReservation="reservation"
            :key="viewPageComponents.revisions"
          />
          <reservation-payments
            v-if="visiblePageComponent === viewPageComponents.payments"
            :reservationId="reservation.Id"
            :key="viewPageComponents.payments"
          />

          <invoice-detail
            v-if="visiblePageComponent === viewPageComponents.invoiceDeposit"
            :key="viewPageComponents.invoiceDeposit"
            :invoiceId="reservation.HasDepositInvoice"
          />

          <invoice-detail
            v-if="visiblePageComponent === viewPageComponents.invoiceDetail"
            :key="viewPageComponents.invoiceDetail"
            :invoiceId="reservation.HasInvoice"
          />

          <ReservationExternalLinks
            :key="viewPageComponents.externalLinks"
            v-if="visiblePageComponent === viewPageComponents.externalLinks"
          />
          <!-- </transition> -->
        </div>
      </template>
    </layout-page-menu>
  </transition>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import reservationProvider from '@/providers/reservation'

const InvoiceDetail = () => import('@/components/Invoices/InvoiceDetail')
const ReservationComments = () =>
  import('@/components/Reservations/ReservationComments')
const ReservationContacts = () =>
  import('@/components/Reservations/ReservationContacts')
const ReservationDetail = () =>
  import('@/components/Reservations/ReservationDetail')
const ReservationNotes = () =>
  import('@/components/Reservations/ReservationNotes')
const ReservationToDos = () =>
  import('@/components/Reservations/ReservationToDos')
const ReservationProgram = () =>
  import('@/components/Reservations/ReservationProgram')
const ReservationCheckins = () =>
  import('@/components/Reservations/ReservationCheckins')
const ReservationMail = () =>
  import('@/components/Reservations/ReservationMail')
const ReservationEvent = () =>
  import('@/components/Reservations/ReservationEvent')
const ReservationRevisions = () =>
  import('@/components/Reservations/ReservationRevisions')
const ReservationPayments = () =>
  import('@/components/Reservations/ReservationPayments')
const ReservationExternalLinks = () =>
  import('@/components/Reservations/ReservationExternalLinks')

export default {
  components: {
    'invoice-detail': InvoiceDetail,
    'reservation-checkins': ReservationCheckins,
    ReservationDetail,
    'reservation-comments': ReservationComments,
    'reservation-contacts': ReservationContacts,
    'reservation-notes': ReservationNotes,
    'reservation-todos': ReservationToDos,
    'reservation-program': ReservationProgram,
    'reservation-mail': ReservationMail,
    'reservation-event': ReservationEvent,
    'reservation-revisions': ReservationRevisions,
    'reservation-payments': ReservationPayments,
    ReservationExternalLinks,
  },

  data() {
    return {
      pageLoadReady: false,
      reservationId: this.$route.params.reservationId,
      reservationProfile: {
        ReservationId: 0,
        ProfileId: 0,
        ProfileKey: '',
        ProfileName: '',
        ProfileImage: '',
      },
      reservationProfiles: [],
      viewPageComponents: {
        detail: 'detail',
        comments: 'comments',
        contacts: 'contacts',
        notes: 'notes',
        todos: 'todos',
        program: 'program',
        checkins: 'checkins',
        maillog: 'maillog',
        eventCalendar: 'eventCalendar',
        revisions: 'revisions',
        payments: 'payments',
        invoiceDeposit: 'invoiceDeposit',
        invoiceDetail: 'invoiceDetail',
        externalLinks: 'externalLinks',
      },
      pageMenu: [],
    }
  },

  computed: {
    ...mapState('menuStore', ['visiblePageComponent']),
    ...mapState('reservationStore', ['reservation']),
    //...mapGetters({ profile: 'profileStore/getProfile' }),

    reservationRouteChanged() {
      let result = false

      let reservationId = this.$route.params.reservationId

      if (this.reservation && reservationId != this.reservation.Id) {
        result = true
      }

      return result
    },

    pageTitle() {
      let temp = ''
      if (this.reservation) {
        temp = 'Reservation: ' + this.reservation.Id
        temp += `; ${this.reservation.LocationName}`
      }
      return temp
    },

    pageSubTitle() {
      let temp = ''
      if (this.reservation) {
        if (this.reservation.MeetingtypeId < 3) {
          temp += `${this.$options.filters.dateFormat(
            this.reservation.StartDate,
            this.$i18n.locale,
            'longDateFormat'
          )}`
          temp += `, ${this.$options.filters.minutesToTime(
            this.reservation.StartMinutes
          )} - ${this.$options.filters.minutesToTime(
            this.reservation.EndMinutes
          )}`
        } else {
          temp += `${this.$options.filters.dateFormat(
            this.reservation.StartDate,
            this.$i18n.locale,
            'longDateFormat'
          )} - ${this.$options.filters.dateFormat(
            this.reservation.EndDate,
            this.$i18n.locale,
            'longDateFormat'
          )}`
        }
      }
      return temp
    },

    previousInSet() {
      let result = -1

      if (this.reservation && this.reservation.SetId > 0) {
        let setIndex = this.reservation.ReservationSetIds.findIndex(
          (r) => r.Id === this.reservation.Id
        )
        if (setIndex > 0) {
          result = this.reservation.ReservationSetIds[setIndex].Id
        }
      }

      return result
    },

    nextInSet() {
      let result = -1

      if (
        this.reservation &&
        this.reservation.SetId > 0 &&
        this.reservation.ReservationSetIds.length > 0
      ) {
        let setIndex = this.reservation.ReservationSetIds.findIndex(
          (r) => r.Id === this.reservation.Id
        )
        if (setIndex > -1) {
          if (setIndex < this.reservation.ReservationSetIds.length - 1) {
            result = this.reservation.ReservationSetIds[setIndex].Id
          }
        }
      }

      return result
    },
  },

  watch: {
    reservationRouteChanged(val) {
      if (val) {
        this.setReservation(null)

        let reservationId = this.$route.params.reservationId
        this.goToReservation(reservationId)
        this.getReservationData()
      }
    },
  },

  created() {
    let self = this

    this.setVisiblePageComponent(this.viewPageComponents.detail)

    if (!this.reservation) {
      this.getReservationData()
    } else {
      this.setupSubMenu()
      this.pageLoadReady = true
    }

    // // Join reservation group
    // self.$reservationHub.joinReservationGroup(self.reservationId)

    // this.reservationProfile.ReservationId = Number(this.reservationId)
    // this.reservationProfile.ProfileId = Number(this.profile.Id)
    // this.reservationProfile.ProfileKey = this.profile.Key
    // this.reservationProfile.ProfileName = this.profile.FullName
    // this.reservationProfile.ProfileImage = this.profile.Image
  },

  mounted() {
    let self = this

    // self.announcePresenceInReservation()

    // self.$reservationHub.connection.on(
    //   self.$reservationHub.listeners.profileEnteredReservation,
    //   (response) => {
    //     let profile = JSON.parse(response)
    //     let profileIndex = self.reservationProfiles.findIndex(
    //       (p) => p.ProfileId === profile.ProfileId
    //     )
    //     if (profileIndex === -1) {
    //       self.reservationProfiles.push(profile)
    //       self.announcePresenceInReservation()
    //     }
    //   }
    // )

    // self.$reservationHub.connection.on(
    //   self.$reservationHub.listeners.profileExitedReservation,
    //   (response) => {
    //     let profile = JSON.parse(response)
    //     let profileIndex = self.reservationProfiles.findIndex(
    //       (p) => p.ProfileId === profile.ProfileId
    //     )
    //     if (profileIndex > -1) {
    //       self.reservationProfiles.splice(profileIndex, 1)
    //     }
    //   }
    // )

    // // Listen for reservation updates
    // self.$reservationHub.connection.on(
    //   self.$reservationHub.listeners.reservationUpdated,
    //   (response) => {
    //     self.updateReservationStore(JSON.parse(response))
    //   }
    // )

    // // Listen for reservation cancellation
    // self.$reservationHub.connection.on(
    //   self.$reservationHub.listeners.reservationCancelled,
    //   (response) => {
    //     self.cancelReservation(JSON.parse(response))
    //   }
    // )

    // // Listen for invoice status changes
    // self.$reservationHub.connection.on(
    //   self.$reservationHub.listeners.invoiceStatusChanged,
    //   (response) => {
    //     let reservation = JSON.parse(response)

    //     self.updateReservation(reservation)
    //     self.setupSubMenu()

    //     if (reservation.HasInvoice > 0) {
    //       let invoiceMenuIndex = this.pageMenu.findIndex(
    //         (m) => m.Name === 'Invoice'
    //       )
    //       if (invoiceMenuIndex > -1) {
    //         this.setSelectedMenuItemIndex({
    //           menuGroupIndex: 2,
    //           selectedMenuItemIndex: Number(invoiceMenuIndex),
    //         })
    //         this.setVisiblePageComponent(this.pageMenu[invoiceMenuIndex].Link)
    //       }
    //     } else {
    //       this.setSelectedMenuItemIndex({
    //         menuGroupIndex: 2,
    //         selectedMenuItemIndex: 0,
    //       })
    //       this.setVisiblePageComponent(this.pageMenu[0].Link)
    //     }
    //   }
    // )
  },

  beforeDestroy() {
    let self = this

    // self.$reservationHub.profileExitedReservation(self.reservationProfile)

    // /**
    //  * Leave group
    //  */
    // self.$reservationHub.leaveReservationGroup(self.reservationId)

    self.setReservation(null)

    self.setPageView(null, 0)
  },

  methods: {
    ...mapMutations('menuStore', [
      'setSelectedMenuItemIndex',
      'setVisiblePageComponent',
    ]),
    ...mapMutations('reservationStore', ['setReservation']),

    /**
     * Get reservation data call
     */
    getReservationData() {
      let self = this

      let reservationId = this.$route.params.reservationId

      reservationProvider.methods
        .getReservationById(reservationId)
        .then((response) => {
          if (response.status === 200) {
            // Update store
            self.updateReservationStore(response.data)

            self.setupSubMenu()
          }
        })
        .finally(() => {
          self.pageLoadReady = true
        })
    },

    announcePresenceInReservation() {
      let self = this
      self.$reservationHub.profileEnteredReservation(self.reservationProfile)
    },

    cancelReservation(reservation) {
      let self = this
      self.reservation.StatusId = 3

      // Update store
      self.updateReservationStore(self.reservation)
    },

    updateReservation(reservation) {
      let self = this

      self.reservation.HasDepositInvoice = reservation.HasDepositInvoice
      self.reservation.HasInvoice = reservation.HasInvoice

      // Update store
      self.updateReservationStore(self.reservation)

      self.setupSubMenu()
    },

    setPageView(item, functionMenuIndex) {
      this.setSelectedMenuItemIndex({
        menuGroupIndex: 2,
        selectedMenuItemIndex: Number(functionMenuIndex),
      })
    },

    /**
     * Setup submenu
     */
    setupSubMenu() {
      let self = this

      let menuItems = [
        {
          Name: this.$t(
            'Views.Reservation.ReservationDetails.Submenu_Reservation'
          ),
          Link: self.viewPageComponents.detail,
        },
        {
          Name: this.$t(
            'Views.Reservation.ReservationDetails.Submenu_Comments'
          ),
          Link: self.viewPageComponents.comments,
          BadgeValue: self.reservation.Comments.length,
        },
        {
          Name: this.$t(
            'Views.Reservation.ReservationDetails.Submenu_Contacts'
          ),
          Link: self.viewPageComponents.contacts,
        },
        {
          Name: this.$t('Views.Reservation.ReservationDetails.Submenu_Notes'),
          Link: self.viewPageComponents.notes,
          BadgeValue: self.reservation.Notes.length,
        },
        {
          Name: this.$t('Views.Reservation.ReservationDetails.Submenu_ToDos'),
          Link: self.viewPageComponents.todos,
          BadgeValue: self.reservation.ToDos.length,
        },
        {
          Name: this.$t('Views.Reservation.ReservationDetails.Submenu_Program'),
          Link: self.viewPageComponents.program,
        },
        // {
        //   Name: this.$t('Views.Reservation.ReservationDetails.Submenu_Checkins'),
        //   Link: self.viewPageComponents.checkins,
        // },
        {
          Name: this.$t('Views.Reservation.ReservationDetails.Submenu_Maillog'),
          Link: self.viewPageComponents.maillog,
        },
        {
          Name: this.$t(
            'Views.Reservation.ReservationDetails.Submenu_EventCalendar'
          ),
          Link: self.viewPageComponents.eventCalendar,
        },
        {
          Name: this.$t(
            'Views.Reservation.ReservationDetails.Submenu_Revisions'
          ),
          Link: self.viewPageComponents.revisions,
        },
        {
          Name: this.$t(
            'Views.Reservation.ReservationDetails.Submenu_Payments'
          ),
          Link: self.viewPageComponents.payments,
        },
      ]

      if (self.reservation.HasInvoice) {
        menuItems.push({
          Name: this.$t('Views.Reservation.ReservationDetails.Submenu_Invoice'),
          //CustomClickAction: this.setPageView,
          Link: self.viewPageComponents.invoiceDetail,
        })
      }

      if (self.reservation.HasDepositInvoice) {
        menuItems.push({
          Name: this.$t(
            'Views.Reservation.ReservationDetails.Submenu_DepositInvoice'
          ),
          //CustomClickAction: this.setPageView,
          Link: this.viewPageComponents.invoiceDeposit,
        })
      }

      menuItems.push({
        Name: this.$t(
          'Views.Reservation.ReservationDetails.Submenu_ExternalLinks'
        ),
        //CustomClickAction: this.setPageView,
        Link: self.viewPageComponents.externalLinks,
      })

      this.pageMenu = menuItems
    },

    /**
     * Update reservation state and original reservation
     */
    updateReservationStore(data) {
      this.setReservation(data)
    },

    goToPreviousReservationInSet() {
      let setIndex = this.reservation.ReservationSetIds.findIndex(
        (r) => r.Id === this.reservation.Id
      )
      let previousReservation = this.reservation.ReservationSetIds[setIndex - 1]
      this.goToReservation(previousReservation.Id)
    },

    goToNextReservationInSet() {
      let setIndex = this.reservation.ReservationSetIds.findIndex(
        (r) => r.Id === this.reservation.Id
      )
      let nextReservation = this.reservation.ReservationSetIds[setIndex + 1]
      this.goToReservation(nextReservation.Id)
    },

    goToReservation(reservationId) {
      this.$router
        .push({
          name: `reservations-list-detail`,
          params: { reservationId: reservationId },
        })
        .catch((err) => {})
    },
  },
}
</script>
